import { type FormikProps, useFormik } from 'formik';
import React, { useCallback } from 'react';
import * as yup from 'yup';

import { useSettings } from '../../context/SettingsContext';

// TODO: remove duplicate, use common form component for create & update
const suggestAllValidationSchema = (supportedLanguages: string[]) =>
   yup.object().shape({
      language: yup
         .string()
         .oneOf(supportedLanguages, 'Invalid language')
         .required('Language is required')
   });

export type SuggestAllFormValues = {
   language: string;
};

type Props = {
   onClose: () => void;
   onSave: (values: SuggestAllFormValues) => Promise<void>;
};
const SuggestAllModal = ({ onClose, onSave }: Props) => {
   const { supportedLanguages } = useSettings();
   const handleBgClose = useCallback(
      (e) => {
         if (e.target.id === 'wrapper') {
            onClose();
         }
      },
      [onClose],
   );

   const {
      values,
      errors,
      touched,
      isSubmitting,
      handleChange,
      handleBlur,
      handleSubmit,
   } = useFormik<SuggestAllFormValues>({
      initialValues: {
         language: '',
      },
      validationSchema: suggestAllValidationSchema(supportedLanguages),
      onSubmit: onSave,
   });

   return (
      <div
         id='wrapper'
         className='fixed inset-0 z-10 flex items-center justify-center bg-black bg-opacity-25 backdrop-blur-sm'
         onClick={handleBgClose}
      >
         {/* Header */}
         <div className='flex flex-col rounded-lg bg-white px-10 py-5'>
            <div className='flex items-center justify-between'>
               <h1 className='text-xl font-medium'>Suggest All</h1>
               <button
                  className='text-boatpark-black underline'
                  onClick={() => {
                     onClose();
                  }}
               >
                  Close
               </button>
            </div>

            {/* Form Items */}
            <div className='mt-8 flex flex-col gap-y-2'>
               <FormItem
                  required
                  name='language'
                  value={values.language}
                  error={errors.language}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                />
            </div>

            {/* Action Buttons */}
            <div className='mt-8 flex flex-row justify-end'>
               <button
                  onClick={(e) => {
                     e.preventDefault();
                     onClose();
                  }}
               >
                  <span className='text-red-500 underline'>Cancel</span>
               </button>
               <button
                  type='submit'
                  disabled={isSubmitting}
                  className='flex items-center ml-4 rounded-md border bg-boatpark-blue px-4 py-1'
                  onClick={() => handleSubmit()}
               >
                  <span className='font-medium text-white'>Save</span>
                  {isSubmitting ? (
                     <svg
                        className="animate-spin h-4 w-4 text-white ml-2"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                     >
                        <circle
                           className="opacity-25"
                           cx="12"
                           cy="12"
                           r="10"
                           stroke="currentColor"
                           strokeWidth="4"
                        ></circle>
                        <path
                           className="opacity-75"
                           fill="currentColor"
                           d="M4 12a8 8 0 018-8v8h8a8 8 0 11-16 0z"
                        ></path>
                     </svg>
                  ) : null}
               </button>
            </div>
         </div>
      </div>
   );
};

type FormItemProps = {
   name: string;
   value: string;
   error?: string;
   required?: boolean;
   handleBlur: FormikProps<SuggestAllFormValues>['handleChange'];
   handleChange: FormikProps<SuggestAllFormValues>['handleChange'];
};
const FormItem = ({
   name,
   value,
   error,
   required,
   handleBlur,
   handleChange,
}: FormItemProps) => {
   const { supportedLanguages } = useSettings();
   return (
      <div>
         <div className='flex flex-row gap-x-4'>
            <label className='w-[120px] font-medium text-gray-500'>
               {required ? <span className='text-red-500'>*</span> : null}
               Language
            </label>
            <select className='flex-shrink rounded border border-black px-5' id={name} name={name} value={value} onBlur={handleBlur} onChange={handleChange}>
                <option value="" disabled>
                  Select a language
                </option>
                {supportedLanguages.map((lang) => (
                    lang !== 'en' && (
                      <option key={lang} value={lang}>
                      {lang}
                      </option>
                    )
                ))}
            </select>
         </div>
         {error ? <span className='text-xs text-red-200'>{error}</span> : null}
      </div>
   );
};

export default SuggestAllModal;
