import { type FormikProps, useFormik } from 'formik';
import React, { useCallback, useEffect } from 'react';
import * as yup from 'yup';

import { useSettings } from '../../context/SettingsContext';

// TODO: remove duplicate, use common form component for create & update
const addTranslationValidationSchema = (supportedLanguages: string[]) =>
   yup.object().shape({
      namespace: yup.string().required('This field is required!'),
      name: yup.string().required('This field is required!'),
      ...Object.fromEntries(
         supportedLanguages.map((lang) => [
            lang, 
            lang === 'en' ? yup.string().required('This field is required!') : yup.string(),
         ]),
      ),
   });

export type TranslationFormValues = {
   namespace: string;
   name: string;
} & Record<string, string>;

type Props = {
   onClose: () => void;
   onSave: (values: TranslationFormValues) => Promise<void>;
};
const AddTranslationModal = ({ onClose, onSave }: Props) => {
   const { supportedLanguages } = useSettings();
   const handleBgClose = useCallback(
      (e) => {
         if (e.target.id === 'wrapper') {
            onClose();
         }
      },
      [onClose],
   );

   const {
      values,
      errors,
      touched,
      isSubmitting,
      handleChange,
      handleBlur,
      handleSubmit,
      setFieldValue
   } = useFormik<TranslationFormValues>({
      initialValues: {
         namespace: '',
         name: '',
         ...Object.fromEntries(
            supportedLanguages.map((lang) => [lang, '']),
         ),
      },
      validationSchema: addTranslationValidationSchema(supportedLanguages),
      onSubmit: async (values) => {
         sessionStorage.setItem('AddTranslationModalLastNamespace', values.namespace);
         await onSave(values);
      }
   });

   useEffect(() => {
      const lastNamespace = sessionStorage.getItem('AddTranslationModalLastNamespace');
      if (lastNamespace && values.namespace !== lastNamespace) {
         setFieldValue('namespace', lastNamespace);
      }
   });

   return (
      <div
         id='wrapper'
         className='fixed inset-0 z-10 flex items-center justify-center bg-black bg-opacity-25 backdrop-blur-sm'
         onClick={handleBgClose}
      >
         {/* Header */}
         <div className='flex flex-col rounded-lg bg-white px-10 py-5'>
            <div className='flex items-center justify-between'>
               <h1 className='text-xl font-medium'>Add Translation</h1>
               <button
                  className='text-boatpark-black underline'
                  onClick={() => {
                     onClose();
                  }}
               >
                  Close
               </button>
            </div>

            {/* Form Items */}
            <div className='mt-8 flex flex-col gap-y-2'>
               <FormItem
                  required
                  name='namespace'
                  value={values.namespace}
                  error={touched.namespace && errors.namespace}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
               />
               <hr />
               <FormItem
                  required
                  name='name'
                  value={values.name}
                  error={touched.name && errors.name}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
               />
               <hr />
               {supportedLanguages.map((lang) => (
                  <React.Fragment key={lang}>
                     <FormItem
                        name={lang}
                        value={values[lang]}
                        error={touched[lang] && errors[lang]}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                     />
                     <hr />
                  </React.Fragment>
               ))}
            </div>

            {/* Action Buttons */}
            <div className='mt-8 flex flex-row justify-end'>
               <button
                  onClick={(e) => {
                     e.preventDefault();
                     onClose();
                  }}
               >
                  <span className='text-red-500 underline'>Cancel</span>
               </button>
               <button
                  type='submit'
                  disabled={isSubmitting}
                  className='ml-4 rounded-md border bg-boatpark-blue px-4 py-1'
                  onClick={() => handleSubmit()}
               >
                  <span className='font-medium text-white'>Save</span>
               </button>
            </div>
         </div>
      </div>
   );
};

type FormItemProps = {
   name: string;
   value: string;
   error?: string;
   required?: boolean;
   handleBlur: FormikProps<TranslationFormValues>['handleChange'];
   handleChange: FormikProps<TranslationFormValues>['handleChange'];
};
const FormItem = ({
   name,
   value,
   error,
   required,
   handleBlur,
   handleChange,
}: FormItemProps) => {
   return (
      <div>
         <div className='flex flex-row gap-x-4'>
            <label className='w-[120px] font-medium text-gray-500'>
               {required ? <span className='text-red-500'>*</span> : null}
               {`${name}:`}
            </label>
            <input
               className='flex-shrink rounded border border-black px-5'
               id={name}
               name={name}
               value={value}
               onBlur={handleBlur}
               onChange={handleChange}
               type='text'
            />
         </div>
         {error ? <span className='text-xs text-red-200'>{error}</span> : null}
      </div>
   );
};

export default AddTranslationModal;
