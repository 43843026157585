import React, { createContext, useContext, useEffect, useState } from 'react';

import translationApi from '../api/translationApi';

export type Settings = {
  supportedLanguages: string[];
};

type SettingsContextType = {
  settings: Settings;
};

const SettingsContext = createContext<SettingsContextType | null>(null);

export const SettingsProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [settings, setSettings] = useState<Settings>({ supportedLanguages: [] });

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const response = await translationApi.getSupportedLanguages();
        const data: Settings = { supportedLanguages: response };
        setSettings(data);
      } catch (error) {
        console.error('Failed to fetch settings', error);
        setSettings({ supportedLanguages: ['en'] });
      }
    };

    fetchSettings();
  }, []);

  return (
    <SettingsContext.Provider value={{ settings }}>
      {children}
    </SettingsContext.Provider>
  );
};

export const useSettings = () => {
  const context = useContext(SettingsContext);
  if (!context) {
    throw new Error('useSettings must be used within a SettingsProvider');
  }
  return context.settings;
};