import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';

import translationApi from '../api/translationApi';
import AddTranslationModal, {
   type TranslationFormValues,
} from '../components/translation/AddTranslationModal';
import SuggestAllModal, {
   type SuggestAllFormValues,
} from '../components/translation/SuggestAllModal';
import TranslationCard from '../components/translation/TranslationCard';
import { useSettings } from '../context/SettingsContext';
import type { TranslationLanguageKey, TranslationObj, TranslationProjectKey } from '../types';
import { cn } from '../utils/ui';

type Props = {
   project: TranslationProjectKey;
};

const projectNames = {
   boatpark_web: 'Boatpark Web',
   boatpark_app: 'Boatpark App',
   boatpark_api: 'Boatpark API',
};

const TranslationPage = ({ project }: Props) => {
   const { supportedLanguages } = useSettings();
   const [translations, setTranslations] = useState<TranslationObj[]>([]);
   const [isLoading, setIsLoading] = useState(false);
   const [showAddTranslationModal, setShowAddTranslationModal] =
      useState(false);
   const [showSuggestAllModal, setShowSuggestAllModal] = useState(false);
   const [showNotTranslated, setShowNotTranslated] = useState(false);

   const filteredTranslations = useMemo(() => {
      if (showNotTranslated) {
         return translations.filter((t) => 
            supportedLanguages
               .filter((lang) => lang !== 'en')
               .some((lang) => !t[lang])
         );
      } else return translations;
   }, [showNotTranslated, translations, supportedLanguages]);

   const fetchAllTranslations = useCallback(
      async (project: TranslationProjectKey) => {
         try {
            setIsLoading(true);
            const res =
               await translationApi.getAllTranslationsByProject(project);
            console.log('fetchAllTranslations: ', res);
            setTranslations(res);
         } catch (err) {
            console.log('fetchAllTranslations err: ', err);
            setTranslations([]);
         } finally {
            setIsLoading(false);
         }
      },
      [],
   );

   useEffect(() => {
      fetchAllTranslations(project);
   }, [fetchAllTranslations, project]);

   const closeAddTranslationModal = useCallback(() => {
      setShowAddTranslationModal(false);
   }, []);

   const closeSuggestAllModal = useCallback(() => {
      setShowSuggestAllModal(false);
   }, []);

   const onAddTranslation = useCallback(
      async (values: TranslationFormValues) => {
         try {
            const res = await translationApi.addTranslation({
               ...values,
               project,
            });
            console.log('translation res: ', res);
            toast('Added translation successfully', { type: 'success' });
            setTranslations((prev) => [...prev, res]);
            // fetchAllTranslations();
            setShowAddTranslationModal(false);
         } catch (err) {
            console.error('saveTranslation err: ', err);
            toast('Error: ' + err, { type: 'error' });
         }
      },
      [project],
   );

   const onSuggestAll = useCallback(
      async (values: SuggestAllFormValues) => {

         const isTranslationLanguageKey = (value: string): value is TranslationLanguageKey => supportedLanguages.includes(value as TranslationLanguageKey);

         if (!isTranslationLanguageKey(values.language)) {
            toast('Invalid language selected', { type: 'error' });
            return;
          }
         try {
            console.log(values.language);
            const res = await translationApi.translateAll(values.language, project);
            console.log('onSuggestAll res: ', res);
            toast('Suggested all translations successfully', {
               type: 'success',
            });
            setTranslations(res);
            // fetchAllTranslations();
            setShowSuggestAllModal(false);
         } catch (err) {
            console.error('onSuggestAll err: ', err);
            toast('Error: ' + err, { type: 'error' });
         }
      },
      [project, supportedLanguages],
   );

   const onUpdateTranslation = useCallback(
      async (id: TranslationObj['id'], values: TranslationObj) => {
         try {
            const res = await translationApi.updateTranslation({
               id,
               project,
               ...values,
            });
            console.log('onUpdateTranslation res: ', res);
            toast('Updated translation successfully', { type: 'success' });
            setTranslations((prev) => {
               const arr = [...prev];
               const idx = arr.findIndex((t) => t.id === id);
               if (idx > -1) {
                  arr[idx] = res;
               }
               return arr;
            });
            // fetchAllTranslations();
         } catch (err) {
            console.error('onUpdateTranslation err: ', err);
            toast('Error: ' + err, { type: 'error' });
         }
      },
      [project],
   );

   const onDeleteTranslation = useCallback(async (id: TranslationObj['id']) => {
      console.log('onDeleteTranslation()');
      try {
         const res = await translationApi.deleteTranslation(id);
         console.log('onDeleteTranslation res: ', res);
         toast('Deleted translation successfully', { type: 'success' });
         setTranslations((prev) => {
            const arr = [...prev];
            const idx = arr.findIndex((t) => t.id === id);
            if (idx > -1) {
               arr.splice(idx, 1);
            }
            return arr;
         });
         // fetchAllTranslations();
      } catch (err) {
         console.error('onDeleteTranslation err: ', err);
         toast('Error: ' + err, { type: 'error' });
      }
   }, []);

   return (
      <div className='h-full w-full flex-col'>
         {showAddTranslationModal ? (
            <AddTranslationModal
               onClose={closeAddTranslationModal}
               onSave={onAddTranslation}
            />
         ) : null}
         {showSuggestAllModal ? (
            <SuggestAllModal
               onClose={closeSuggestAllModal}
               onSave={onSuggestAll}
            />
         ) : null}
         {/* Top View */}
         <div className='flex h-full flex-col items-center overflow-auto'>
            <div className='flex w-2/3 flex-row items-center justify-between pt-10'>
               <span className='text-xl text-boatpark-blue'>{`Project: ${projectNames[project] || 'Unknown Project'} Translations`}</span>
               <div className='flex flex-row gap-x-4'>
                  <button
                     className='rounded-md border-2 border-boatpark-blue px-4 py-1 text-boatpark-blue duration-200 hover:bg-boatpark-blue hover:text-white'
                     onClick={() => setShowSuggestAllModal(true)}
                  >
                     Suggest All
                  </button>
                  <button
                     className='rounded-md border-2 border-boatpark-blue px-4 py-1 text-boatpark-blue duration-200 hover:bg-boatpark-blue hover:text-white'
                     onClick={() => setShowAddTranslationModal(true)}
                  >
                     Add Translation
                  </button>
               </div>
            </div>

            <div className='my-4 flex w-2/3 flex-row items-center'>
               <button
                  className={cn(
                     'rounded-md border-2 border-boatpark-blue px-4 py-1 text-boatpark-blue',
                     !showNotTranslated
                        ? 'bg-boatpark-blue font-medium text-white'
                        : null,
                  )}
                  onClick={() => setShowNotTranslated(false)}
               >
                  All
               </button>
               <button
                  className={cn(
                     'ml-2.5 rounded-md border-2 border-boatpark-blue px-4 py-1 text-boatpark-blue',
                     showNotTranslated
                        ? 'bg-boatpark-blue font-medium text-white'
                        : null,
                  )}
                  onClick={() => setShowNotTranslated(true)}
               >
                  Not Translated
               </button>
            </div>

            {/* Content View*/}
            <div className='my-10 flex w-2/3 flex-col items-center'>
               {isLoading ? (
                  <b>Loading..</b>
               ) : (
                  <div className='flex w-full flex-col gap-y-4'>
                     {filteredTranslations.map((translationObj) => (
                        <TranslationCard
                           key={translationObj.id}
                           info={translationObj}
                           onDelete={onDeleteTranslation}
                           onUpdate={onUpdateTranslation}
                        />
                     ))}
                  </div>
               )}
            </div>
         </div>
      </div>
   );
};

export default TranslationPage;
