import type {
   TranslationLanguageKey,
   TranslationObj,
   TranslationProjectKey,
} from '../types';
import request from './_request';

const endpoints = {
   getAll: '/translations/get_all',
   getAllByProject: '/translations/get_by_project',
   get: '/translations/get',
   add: '/translations/add',
   update: '/translations/update',
   delete: '/translations/delete',
   getTranslationSuggestion: '/translations/get_translation_suggestion',
   getJson: '/translations/get_json',
   translateAll: '/translations/translate_all',
   get_supported_languages: '/translations/get_supported_languages',
};

export default {
   getAllTranslations: () => {
      return request.get<TranslationObj[]>(endpoints.getAll);
   },
   getAllTranslationsByProject: (project: TranslationProjectKey) => {
      return request.get<TranslationObj[]>(endpoints.getAllByProject, {
         project,
      });
   },
   getTranslation: (id: TranslationObj['id']) => {
      return request.get<TranslationObj & { status: string }>(
         endpoints.getAll,
         { id },
      );
   },
   addTranslation: (body: TranslationObj) => {
      return request.post<TranslationObj & { status: string }>(
         endpoints.add,
         body,
      );
   },
   updateTranslation: (body: TranslationObj) => {
      return request.post<TranslationObj & { status: string }>(
         endpoints.update,
         body,
      );
   },
   deleteTranslation: (id: TranslationObj['id']) => {
      return request.post<TranslationObj & { status: string }>(
         endpoints.delete,
         { id },
      );
   },
   getTranslationSuggestion: (id: TranslationObj['id']) => {
      return request.get<TranslationObj & { status: string }>(
         endpoints.getTranslationSuggestion,
         { id },
      );
   },
   getLanguageJson: (language: TranslationLanguageKey) => {
      return request.get<Record<string, Record<string, string | string[]>>>(
         endpoints.getTranslationSuggestion,
         { language },
      );
   },
   translateAll: (
      language: TranslationLanguageKey,
      project: TranslationProjectKey,
   ) => {
      return request.get<TranslationObj[]>(endpoints.translateAll, {
         language,
         project,
      });
   },
   getSupportedLanguages: async () => {
      // return ['en', 'de', 'fr', 'it', 'es', 'da'];
      try {
         return await request.get<string[]>(endpoints.get_supported_languages);
      } catch (error) {
         console.error('Failed to fetch supported languages:', error);
         return ['en', 'de', 'fr', 'it', 'es', 'da'];
      }
   },
};
