import { type FormikProps, useFormik } from 'formik';
import React, { useCallback, useState } from 'react';
import * as yup from 'yup';

import translationApi from '../../api/translationApi';
import { useSettings } from '../../context/SettingsContext';
import type { TranslationObj } from '../../types';
import ConfirmModal from '../ConfirmModal';

type Props = {
   info: TranslationObj;
   onDelete: (id: TranslationObj['id']) => Promise<void>;
   onUpdate: (
      id: TranslationObj['id'],
      values: TranslationObj,
   ) => Promise<void>;
};

const TranslationCard = ({ info, onDelete, onUpdate }: Props) => {
   const { supportedLanguages } = useSettings();
   const [editing, setEditing] = useState(false);
   const [showDeleteDialog, setShowDeleteDialog] = useState(false);
   const [suggestion, setSuggestion] = useState<TranslationObj>(null);

   const onSuggestClick = useCallback(async () => {
      try {
         const res = await translationApi.getTranslationSuggestion(info.id);
         console.log('onSuggestClick res: ', res);
         setSuggestion(res);
         setEditing(true);
      } catch (err) {
         console.log('onSuggestClick err: ', err);
      }
   }, [info.id]);

   const onSaveClick = useCallback(
      async (values) => {
         await onUpdate(info.id, values);
         setSuggestion(null);
         setEditing(false);
      },
      [info.id, onUpdate],
   );

   return (
      <div className='flex flex-col gap-y-2 rounded-md bg-boatpark-gray-light px-20 py-10 shadow-md'>
         {showDeleteDialog ? (
            <ConfirmModal
               message={`Delete translation for ${info.namespace}.${info.name}`}
               confirmText='Delete'
               onCancelClick={() => {
                  setShowDeleteDialog(false);
               }}
               onConfirmClick={async () => {
                  await onDelete(info.id);
                  setShowDeleteDialog(false);
               }}
            />
         ) : null}

         {/* Action Buttons */}
         <div className='flex flex-row items-center justify-between py-2.5'>
            <span className='font-medium text-boatpark-blue'>{`${info.namespace}.${info.name}`}</span>
            <div>
               <button
                  onClick={() => {
                     setShowDeleteDialog(true);
                  }}
               >
                  <span className='text-red-500 underline'>Delete</span>
               </button>
               <button className='ml-4' onClick={onSuggestClick}>
                  <span className='text-boatpark-blue underline'>Suggest</span>
               </button>
               {editing ? (
                  <>
                     <span className='ml-4'>Editing...</span>
                  </>
               ) : (
                  <button
                     className='ml-4'
                     onClick={() => {
                        setEditing(true);
                     }}
                  >
                     <span className='text-boatpark-blue underline'>Edit</span>
                  </button>
               )}
            </div>
         </div>

         {editing ? (
            <EditForm
               info={info}
               suggestion={suggestion}
               supportedLanguages={supportedLanguages}
               onCancel={() => {
                  setSuggestion(null);
                  setEditing(false);
               }}
               onSave={onSaveClick}
            />
         ) : (
            <>
               <InfoRow name='namespace' value={info.namespace} />
               <hr />
               <InfoRow name='name' value={info.name} />
               <hr />
               {supportedLanguages.map((lang) => (
                  <React.Fragment key={lang}>
                     <InfoRow name={lang} value={info[lang]} />
                     <hr />
                  </React.Fragment>
               ))}
            </>
         )}
      </div>
   );
};

type InfoRowType = {
   name: string;
   value: string;
};
const InfoRow = ({ name, value }: InfoRowType) => {
   return (
      <div className='flex flex-row items-center gap-x-4'>
         <label className='w-[120px] font-medium text-gray-500'>{`${name}: `}</label>
         <span className='flex-1'>{value}</span>
      </div>
   );
};

const updateTranslationValidationSchema = (supportedLanguages: string[]) =>
   yup.object().shape({
      namespace: yup.string().required('This field is required!'),
      name: yup.string().required('This field is required!'),
      ...Object.fromEntries(
         supportedLanguages.map((lang) => [
            lang,
            lang === 'en'
               ? yup.string().required('This field is required!')
               : yup.string().nullable(),
         ]),
      ),
   });

type TranslationFormValues = {
   namespace: string;
   name: string;
} & Record<string, string | null>;

type EditFormType = {
   info: TranslationObj;
   suggestion?: TranslationObj;
   supportedLanguages: string[];
   onCancel: () => void;
   onSave: (values: TranslationFormValues) => Promise<void>;
};
const EditForm = ({
   info,
   suggestion,
   supportedLanguages,
   onCancel,
   onSave,
}: EditFormType) => {
   const {
      values,
      errors,
      touched,
      isSubmitting,
      handleSubmit,
      handleBlur,
      handleChange,
   } = useFormik<TranslationFormValues>({
      enableReinitialize: true,
      initialValues: {
         namespace: suggestion?.namespace || info.namespace,
         name: suggestion?.name ?? info.name ?? '',
         ...Object.fromEntries(
            supportedLanguages.map((lang) => [
               lang,
               suggestion?.[lang] ?? info[lang] ?? '',
            ]),
         ),
      },
      validationSchema: updateTranslationValidationSchema(supportedLanguages),
      onSubmit: onSave,
   });
   return (
      <div className='flex flex-col'>
         {/* Form Items */}
         <div className='flex flex-col gap-y-2'>
            <FormItem
               required
               name='namespace'
               value={values.namespace}
               error={touched.namespace && errors.namespace}
               handleChange={handleChange}
               handleBlur={handleBlur}
            />
            <hr />
            <FormItem
               required
               name='name'
               value={values.name}
               error={touched.name && errors.name}
               handleChange={handleChange}
               handleBlur={handleBlur}
            />
            <hr />
            {supportedLanguages.map((lang) => (
               <React.Fragment key={lang}>
                  <FormItem
                     name={lang}
                     value={values[lang]}
                     error={touched[lang] && errors[lang]}
                     handleChange={handleChange}
                     handleBlur={handleBlur}
                  />
                  <hr />
               </React.Fragment>
            ))}
         </div>

         {/* Actions */}
         <div className='mt-4 flex flex-row justify-end'>
            <button
               onClick={() => {
                  onCancel();
               }}
            >
               <span className='text-gray-500 underline'>Cancel</span>
            </button>
            <button
               type='submit'
               disabled={isSubmitting}
               className='ml-4 rounded-md border bg-boatpark-blue px-4 py-1'
               onClick={() => handleSubmit()}
            >
               <span className='font-medium text-white'>Save</span>
            </button>
         </div>
      </div>
   );
};
type FormItemProps = {
   name: string;
   value: string;
   error?: string;
   required?: boolean;
   handleBlur: FormikProps<TranslationFormValues>['handleChange'];
   handleChange: FormikProps<TranslationFormValues>['handleChange'];
};
const FormItem = ({
   name,
   value,
   error,
   required,
   handleBlur,
   handleChange,
}: FormItemProps) => {
   return (
      <div>
         <div className='flex flex-row gap-x-4'>
            <label className='w-[120px] font-medium text-gray-500'>
               {required ? <span className='text-red-500'>*</span> : null}
               {`${name}:`}
            </label>
            <input
               className='flex-1 rounded border border-black px-5'
               id={name}
               name={name}
               value={value}
               onBlur={handleBlur}
               onChange={handleChange}
               type='text'
            />
         </div>
         {error ? <span className='text-xs text-red-200'>{error}</span> : null}
      </div>
   );
};

export default TranslationCard;
